import React from 'react'
import Footer from '../components/footer/footer'
import { useStaticQuery, graphql } from "gatsby"
import {Markdown} from 'react-showdown'
import BackButton from '../components/utils/backButton'
import MobileNavbar from '../components/navbar/mobileNavbar'
import SEO from '../components/utils/SEO'
import DefaultLayout from '../components/layouts/defaultLayout'
import FoldableGallery from '../components/gallery/foldableGallery'
import ImageGallery from '../components/gallery/imageGallery'

import {
  VillageWrapper, 
  VillageBackgroundImage, 
  VillageHeaderWrapper,
  VillageContentWrapper,
} from '../components/pages/villageWrapper'

const Village = () => {

    const data = useStaticQuery(graphql`
    query VillageLayout {
          backgroundDesktop : imageSharp(fluid: {originalName: {eq: "village-original.jpg"}}) {
            fluid(quality:90, srcSetBreakpoints:[1920, 2560]) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
          backgroundLaptop : imageSharp(fluid: {originalName: {eq: "village-1024.jpg"}}){
            fluid(quality:90, maxWidth:1440, srcSetBreakpoints:[1440]) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
          backgroundTablet : imageSharp(fluid: {originalName: {eq: "village-1024.jpg"}}){
            fluid (quality:90, maxWidth:1024, srcSetBreakpoints:[1024]){
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
          backgroundMobile : imageSharp(fluid: {originalName: {eq: "village-mobile.jpg"}}){
            fluid (maxWidth:768, srcSetBreakpoints:[768]){
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
          strapiVillage {
            titre
            sous_titre
            description
            gallery {
              localFile {
                childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        
    }
  `)
 
  
  const backgroundSources = [
    data.backgroundMobile.fluid,
    {
      ...data.backgroundTablet.fluid,
      media: "(min-width: 769px) and (max-width: 1024px)",
      sizes: "(max-width: 1024px) 1024px, 100vw"
    },
    {
      ...data.backgroundLaptop.fluid,
      media: "(min-width:1025px) and (max-width: 1440px)",
      sizes: "(max-width:1440px) 1440px, 100vw"
    },
    {
      ...data.backgroundDesktop.fluid,
      media: "(min-width:1441px)",
      sizes: "(min-width:1441px) 100vw, 1441px"
    }
  ]

    return (
        <DefaultLayout>
            <VillageWrapper>
                <SEO title="Village" description="Le village des Branchés à Emosson" />
                <MobileNavbar />
                <h1 className="village-mobile-title">Le village des branchés</h1>
                <VillageBackgroundImage fluid={backgroundSources}>
                  <BackButton />   
                </VillageBackgroundImage>  
                <VillageHeaderWrapper>
                      <h1 className="village-desktop-title">{data.strapiVillage.titre}</h1>
                      <h2>{data.strapiVillage.sous_titre}</h2>
                  </VillageHeaderWrapper> 
                <VillageContentWrapper>
                    <Markdown markup={data.strapiVillage.description} />
                </VillageContentWrapper>
                
                <ImageGallery  images={data.strapiVillage.gallery} />      
            </VillageWrapper>
            <Footer />
        </DefaultLayout>      
    )
}

export default Village