import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import Container from '../utils/container'
import {up, down} from 'styled-breakpoints'

const VillageWrapper = styled.main`
    background: ${p => p.theme.colors.village};
    color: ${p =>  p.theme.colors.white};
    h1{
        font-size: ${p => p.theme.fontSizes["2xl"]}
    }
    .village-mobile-title{
        margin: 10px 0;
        text-align: center;
        font-size: ${p => p.theme.fontSizes.xl}
    }
    ${up("md")}{
        .village-mobile-title{
            display:none;
        }
    }
    ${down("md")}{
        .village-desktop-title{
            display: none;
        }
    }

`

const VillageBackgroundImage = styled(BackgroundImage)`
    min-height: 80vh;
    box-shadow: 0px 9px 20px 0px #0000006e;
`

const VillageHeaderWrapper = styled(Container)`
    margin-top: 20px;
    h1 {
        text-align: center;
        color: ${p => p.theme.colors.white};
        font-size: 3.2rem;
        text-shadow: -1px -1px 19px #000;
        line-height: 150%;
        margin: 0; 
    }
    h2{
        text-align: center;
        font-size: 1.4rem;
    }
`
const VillageContentWrapper = styled(Container)`
    padding-top: 70px;
    padding-bottom: 100px;
    font-size: 25px;

    ${down('md')}{
        padding-left: 25px;
        padding-right: 25px;
    }
`



export {
    VillageWrapper, 
    VillageBackgroundImage, 
    VillageHeaderWrapper,
    VillageContentWrapper
}