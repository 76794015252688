import React, {useState} from 'react'
import styled from 'styled-components'
import ImageGallery from './imageGallery'
import Container from '../utils/container';
import {FaArrowUp} from 'react-icons/fa'
import {down} from 'styled-breakpoints'


const FoldableGalleryWrapper = styled.section`
    transition: 1s ease-in-out;
    .react-photo-gallery--gallery, .imageGallery--image{transition: all 1s linear;}
    
    ${p => !p.isOpen ? 
    `.react-photo-gallery--gallery{
        filter: brightness(.6);
        pointer-events: none;
        max-height: 250px;
    }` 
    : `
    .imageGallery--image:hover{
        border: 2px solid white;
        filter: saturate(1.2);
    }
  `}
   
    overflow: hidden;
`

const GalleryArrow = styled.div`
    transition: 1s ease-in-out;
    ${p => p.isOpen && `
        transform: rotate(-180deg);
    `}
`

const FoldableGalleryTitle = styled(Container).attrs({
    as: 'h1'
})`
    border-bottom: 3px solid ${ p => p.theme.colors.white};
    color: ${ p => p.theme.colors.white};
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    ${down('md')}{
        margin-left: 15px;
        margin-right: 15px;
    }
`

const FoldableGallery = ({images, title}) => {
    const [isOpen, setOpen] = useState(false);
    return(
       <>          
        <FoldableGalleryTitle onClick={() => setOpen(!isOpen)}>
            {title}
            <GalleryArrow isOpen={isOpen} >
                <FaArrowUp />
            </GalleryArrow>>    
        </FoldableGalleryTitle>
        <FoldableGalleryWrapper isOpen={isOpen} onClick={() => setOpen(true)}>
            <ImageGallery images={images} />
        </FoldableGalleryWrapper>
        </>
    )
}
export default FoldableGallery;